.contentPage {
  margin-left: -1px;
  margin-right: -1px;

  .editCaller {
    float: right;
    font-weight: normal;
  }

  .textHolder {
    > h1, > h2.green, h2.darkgreen {
      margin-left: -20px;
      margin-right: -20px;
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }
}

.contentPageWelcome {
  overflow: auto;

  &.hasDeadline {
    min-height: 135px; // Spacing for the deadline circle
  }

  .editCaller {
    float: right;
    margin-left: 10px;
    font-weight: normal;
  }
}

.contentPageFeeds {
  .editCaller {
    float: right;
  }
}

.contentSettingsToolbar {
  padding: 0;
  .textfield {
    padding-top: 10px;
  }
  input[type=text] {
    max-width: 160px;
  }
  .options {
    padding-top: 5px;
    label {
      font-weight: normal;
    }
  }
}

.deadlineCircle {
  float: right;
  width: 105px;
  height: 105px;
  padding-top: 20px;
  @include linear-gradient(90deg, $btn-primary-bg, lighten($btn-primary-bg, 3.5%));
  text-align: center;
  vertical-align: middle;
  overflow: hidden;
  font-family: $deadlineCircleFont;
  font-weight: bold;
  font-size: 15px;
  line-height: 17px;
  text-transform: uppercase;
  color: #fff;
  margin-left: 30px;
  @include border-radius(90px);
}

.downloadableFiles {
  h2 {
    font-size: 1.2em;
  }

  .deleteFile {
    color: $colorDelLink;
  }

  .fileList {
    list-style-type: none;

    @media (hover: hover) {
      li .deleteFile {
        opacity: 0.1;
      }
      li:hover .deleteFile {
        opacity: 1;
      }
      li .deleteFile:focus {
        opacity: 1;
      }
    }
  }

  .downloadableFilesUpload {
    display: flex;

    > * {
      flex: 0;
    }

    h3 {
      margin: 8px 0 0 0;
      flex-basis: 150px;
      font-size: 1em;
    }

    label {
      font-weight: normal;
    }

    .uploadCol {
      flex-basis: 200px;
    }

    .titleCol {
      flex: 1;
      max-width: 300px;
    }
  }
}

.documentsPage {
  .editCaller {
    float: right;
    margin-left: 10px;
    font-weight: normal;
  }

  .downloadAndActions {
    text-align: right;
  }

  .deleteGroupForm {
    display: inline;
    float: right;
    margin-right: 15px;

    .deleteGroupBtn {
      color: $colorDelLink;
      opacity: 0;
      &:active, &:focus {
        opacity: 1;
      }
    }
  }
  .greenHeader:hover {
    .deleteGroupForm .deleteGroupBtn {
      opacity: 1;
    }
  }

  .deleteFileBtn {
    display: inline;
    margin-left: 15px;
    color: $colorDelLink;
    opacity: 0;
    &:active, &:focus {
      opacity: 1;
    }
  }
  .motion .title:hover .deleteFileBtn {
    opacity: 1;
  }

  .btn.btn-link {
    padding: 0;
    font-weight: normal;
  }

  .fileAddForm {
    display: flex;
    flex-direction: row;
    margin-left: 32px;
    margin-top: -25px;
    margin-bottom: 40px;

    .uploadCol {
      label {
        font-weight: normal;
        color: $colorLinks;
      }
    }
  }
}
