$veraSansPath: "." !default;

@font-face {
  font-family: 'VeraMono';
  src: url('#{$veraSansPath}/VeraMono-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'VeraMono';
  src: url('#{$veraSansPath}/VeraMono-Italic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'VeraMono';
  src: url('#{$veraSansPath}/VeraMono-Bold-webfont.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'VeraMono';
  src: url('#{$veraSansPath}/VeraMono-Bold-Italic-webfont.woff') format('woff');
  font-weight: bold;
  font-style: italic;
}
