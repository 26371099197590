#loginSamlHint, .login_saml .privacyHint {
  border-left: solid 1px #808080;
  padding-left: 10px;
  margin-left: 20px;
  margin-top: 10px;
  font-size: 0.8em;
  overflow: auto;
}

.login_saml {
  .privacyHint {
    border-left: solid 1px #808080;
    padding-left: 10px;
    margin-left: 20px;
    margin-top: 0;
    font-size: 0.8em;
    overflow: auto;
  }
  .btn {
    float: left;
    margin-right: 30px;
  }
}

.loginUsername {
  .passwordRecovery {
    text-align: right;
    font-size: 0.8em;
  }
  #regConfirmation {
    font-size: 11px;
  }
}

.userAccountForm {
  .requestEmailChange {
    display: inline-block;
    margin-left: 10px;
    font-style: italic;
    font-size: 0.8em;
  }
  .changeRequested {
    display: block;
    font-size: 0.8em;
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .resendButton {
    color: $colorLinks;
    font-style: italic;
  }
}

.notificationForm {
  label {
    cursor: pointer;
  }
  input[type=checkbox] {
    margin-right: 10px;
  }
  .notificationRow {
    margin-top: 10px;
    margin-bottom: 20px;
  }
  .radioList {
    > div {
      padding-left: 27px;
    }
    label {
      display: block;
      font-weight: normal;
      margin-bottom: 10px;
    }
  }
}

.userDataExport {
  .exportRow {
    margin-top: 20px;
    text-align: center;
  }
}

.askPermissionForm {
  text-align: center;
  margin-top: 50px;
  margin-bottom: 30px;
}
