@font-face {
  font-family: FontAwesome;
  src: url(../fonts/fontawesome-webfont.woff2) format('woff2'), url(../fonts/fontawesome-webfont.woff) format('woff');
  font-weight: 400;
  font-style: normal
}

#gotoMainContent {
  color: transparent;
  position: absolute;
  left: 10px;
  top: 35px;
  &:link, &:visited {
    color: transparent;
  }
  @include outlineOnFocusNoBorder();
  &:focus {
    color: $colorLinks;
  }
}

body {
  font-family: $bodyFont;
  height: 100%;
}

#page {
  margin-left: auto;
  margin-right: auto;
  @media (min-width: $screen-sm-min) {
    width: $container-sm;
  }
  @media (min-width: $screen-md-min) {
    width: $container-md;
  }
  @media (min-width: $screen-lg-min) {
    width: $container-lg;
  }

  body.fullscreen & {
    width: auto;
    margin-left: 10px;
    margin-right: 10px;
  }
}

.logoRow {
  display: flex;
  margin: 14px 0 40px;

  .homeLinkLogo {
    min-width: 20%; // this allows SVGs to be shown - they would get a width of 0% otherwise
    > img {
      max-width: 50%;
      max-height: 200px;
    }
    @media screen and (max-width: $screen-xs) {
      text-align: center;
      > img {
        max-width: 90%;
      }
    }
  }
}

/* keep the footer at the bottom of the page */
$footer_height: 1.6em;
$footer_line_height: 1.5em;

.over_footer_wrapper {
  min-height: 100%;
  height: auto !important;
  height: 100%;
  margin: 0 auto (-1 * $footer_height);
}

#userLoginPanel {
  height: 35px;
  background-color: lightgrey;

  display: flex;
  flex-direction: row;

  .username {
    flex-basis: 50%;
    text-align: left;
    padding: 5px 10px;
  }

  .groups {
    flex-basis: 50%;
    text-align: right;
    padding: 5px 10px;
  }
}

a {
  @include outlineOnFocusNoBorder();
}

body > footer {
  height: $footer_height;
  max-width: $container-md;
  margin-left: auto;
  margin-right: auto;
  padding-right: floor(($grid-gutter-width * 0.5));
  padding-left: ceil(($grid-gutter-width * 0.5));

  p {
    height: $footer_height;
    line-height: $footer_line_height;
    margin-bottom: 0;
    margin-top: 0;
    white-space: nowrap;
  }

  a:link, a:visited {
    color: $colorLinksFooter;
  }

  .version {
    display: inline-block;
    margin-left: 30px;
    font-size: 0.8em;
  }
  @media print {
    display: none;
  }
}

.footer_spacer {
  height: $footer_height;
}

.labelSubInfo {
  font-weight: normal;
  font-size: 0.8em;
}

.antragsgruen-content {
  // The children should behave similar to bootstrap's grids (hence the paddings&floats), but have a configurable width

  > #sidebar {
    max-width: $sidebarWidth;
    float: left;
    padding-right: 0;
    padding-left: ceil(($grid-gutter-width * 0.5));
  }

  .sidebar-box {
    min-width: 200px;

    .box-header {
      @include stdHeading($headingSecondaryText, $headingSecondaryBackground, $headingSecondarySize);
      padding: 5px 5px 5px 15px;
      margin: -1px;
      margin-bottom: 12px;
    }
    .box-content {
      padding: 0 15px 15px 15px;
    }
    &:first-child {
      .box-header {
        @include border-radius-top();
      }
    }
  }

  > .antragsgruen-width-main {
    width: 100%;

    max-width: $mainContentWidth;
    float: left;
  }

  > .antragsgruen-width-full {
    width: 100%;
    float: left;
  }

  > * {
    position: relative;
    min-height: 2px;
  }
}

.goBackLink {
  display: inline-block;
  margin-bottom: 20px;
}

.saveCancelRow {
  overflow: auto;
  .well &.content {
    overflow: auto;
  }
  .saveCol {
    float: right;
  }
  .cancelCol {
    float: left;
  }
}

.toolbarBelowTitle {
  padding: 10px 19px;
  border-bottom: solid 1px #aaa;
  background: #f7f7f7;
  display: table;
  margin-left: -1px;
  margin-right: -1px;
  width: calc(100% + 2px);
  > * {
    display: table-cell;
  }
}

.stickyAdminDebugFooter {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 10;
  padding: 0;
  background: white;
  border-top: solid 1px #ccc;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.4);
  display: table;
  width: 100%;

  > * {
    display: table-cell;
    padding: 5px;
    vertical-align: top;
  }
  .adminHint {
    font-size: 0.8em;
    display: block;
  }
  .setterCol {
    width: 100%;
  }
  #simulateAdminTime {
    width: 200px;
    float: left;
    margin-right: 10px;
  }
  h2 {
    white-space: nowrap;
    margin: 0;
    font-size: 1.1em;
  }
  label {
    margin: 0;
  }
}

*:fullscreen {
  overflow-x: hidden;
  overflow-y: auto;
}
*:-webkit-full-screen {
  overflow-x: hidden;
  overflow-y: auto;
}
*:-moz-full-screen {
  overflow-x: hidden;
  overflow-y: auto;
}
*:-ms-fullscreen {
  overflow-x: hidden;
  overflow-y: auto;
}
